.container{
    line-height: 0;
    position: relative;
    padding: 0.9em 0.6em 1em;
    background-color: #0E4DAB;
    --inv-text-m-color: #fff;
    --inv-text-m-font-weight: 600;
    --inv-text-m-font-size: 1em;
    --inv-text-xs-color: #fff;
    --inv-text-xs-font-weigth: 400;
    --inv-text-xs-font-size: 0.7em;
    border-radius: 0.5em;
}

.container::before {
    content: '';
    position: absolute;
    bottom: -0.8em; /* adjust this value to move the triangle up or down */
    left: 50%;
    transform: translateX(-50%);
    border-left: 1em solid transparent;
    border-right: 1em solid transparent;
    border-top: 1em solid #0E4DAB; /* change this value to adjust the size and color of the triangle */
}