body{
    font-size: 20px;
}

.marginTopText{
    --inv-gutter-y: 0.8em;
}

.marginTopSubText{
    --inv-gutter-y: 0.4em;
}

.marginTopFirstQuestionRow{
    margin-top: 1.725em;
}

.marginBottomQ1And2{
    margin-bottom: 1.6665em;
}

.marginBottomQ3{
    margin-bottom: 1.6665em;
}

.marginTopQ3{
    margin-top: 2.55em;
}

.marginBottomQ4{
    margin-bottom: 1.6665em;
}
.marginTopSelectorQ1{
    --inv-gutter-y: 0.8em;
}
.marginTopInput{
    --inv-gutter-y: 1.6em;
}
.marginTopSlider{
    margin-top: 4.2em;
}
.marginTopElementsQ4{
    margin-top: 1.6em;
}
.marginTopElementAdviceQ4{
    margin-top: 1.6em;
}
.marginTopElementsMoneyQ3{
    margin: 0;
}
.paddingSelector{
    padding: 0 0.8em;
}
.marginTopElementsQ3{
    margin-top: 1.6em;
}
.goalCostTitle{

}

.goalCostSecondTitle{
    margin-top: 1.6em;
}
.goalCostFirstSubtitle{

}
.goalCostSecondSubtitle{
    margin-bottom: 1.8em;
}

.goalCostChart{
    margin-bottom: 1.6em;
}
.goalCostChartValue{
    --inv-gutter-y:2.4em
}

@media (min-width: 576px) {
    body{
        font-size: 13px;
    }

    .marginTopText{
        --inv-gutter-y: 2em;
    }
    .marginTopSubText{
        --inv-gutter-y: 0.8em;
    }
    .marginTopFirstQuestionRow{
        margin-top: 4em;
    }
    .marginBottomQ1And2{
        margin-bottom: 4em;
    }

    .marginTopQ3{
        margin-top: 2em;
    }

    .marginTopElementsMoneyQ3{
        margin: 1.5em 0 0 !important;
    }

    .marginBottomQ3{
        margin-bottom: 4.2em;
    }

    .marginBottomQ4{
        margin-bottom: 2em;
    }
    .marginTopSelectorQ1{
        --inv-gutter-y: 0;
    }
    .marginTopSlider{
        margin-top: 5.5em;
    }
    .marginTopElementsQ4{
        margin-top: 2em;
    }
    .marginTopElementAdviceQ4{
        margin-top: 4em;
    }
    .marginTopElementsQ3{
        margin-top: 0.8em;
    }
    .paddingSelector{
        padding: 0;
    }
    .goalCostTitle{
        --inv-text-m-font-size: 2.4em;
    }
    .goalCostSecondTitle{
        margin-top: 0.8em;
        margin-bottom: 0.8em;
        --inv-text-m-font-size: 2.4em;
    }
    .goalCostFirstSubtitle{
        margin-top: 0.8em;
    }
    .goalCostSecondSubtitle{
        margin-bottom: 3.2em;
    }

    .goalCostChart{
        margin-bottom: 2.8em;
    }
    .goalCostChartValue{
        --inv-text-xxs-font-size: 0.8em;
        --inv-text-xs-font-size: 1.8em;
        --inv-gutter-y:2.9em
    }
    .goalCostChartValueUnit{
        --inv-text-xxs-font-size: 1em;
        --inv-text-xs-font-size: 1em;
    }
    .container{
        --inv-text-xl-font-size: 2.4em;
        --inv-text-l-font-size: 2.4em;
        --inv-text-xs-font-size: 1.2em;
        --inv-text-s-font-size: 1em;
        --inv-text-xxs-font-size: 1em;
        --inv-text-xxxxxl-font-size: 1.2em;
        --inv-text-xxl-font-size: 2.4em;
    }
}