.container {
  font-size: 20px;
  max-width: 1290px;
  margin: 1.2em 0 0;
  background: #fff;
  border-radius: 0;
  width: 100%;
  padding: 2.4em 0.8em 1.6em;
}

.img-container {
  width: 100%;
}
.subtitle1{
  margin:0.8em 0 0;
}

.withAndWithoutInveertContainer{
  margin: 2.4em 0 0;
--inv-gutter-x: 0;
  --inv-gutter-y: 1.6em;
}

.title2{
  margin:1.6em 0 0;
}

.chartInfoContainer{
  margin: 1.2em 0 0.6em;
}
.chartInfoBorderedContainer{
  border:0.025em solid;
  border-radius:0.4em;
  padding:0.4em;
  --inv-gutter-x:0.8em
}

.chart{
  margin: 0 0 2.5em;
}

.divider{
  display: none;
}
.buttonsContainer{
  justify-content: center;
}
.vsContainer{
  display: flex;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  background: var(--primary-color);
  border-radius: 50%;
  width: 3em;
  height: 3em;
  padding: 0.5em;
}
.vsCol{
  margin:0
}

@media (min-width: 576px) {
  .container {
    font-size: 13.3px;
    max-width: 910px;
    margin: 2em auto 10em;
  }
  .img-container {
    width: 41%;
  }
  .subtitle1{
    margin:2em 0 0;
  }
  .withAndWithoutInveertContainer{
    margin: 2.8em 0 0;
    --inv-gutter-y: 0;
  }
  .title2{
    margin:2.8em 0 0;
  }
  .chartInfoContainer{
    margin: 2.5em 0 4.6em;
  }

  .chartInfoBorderedContainer{
    border:none;
    padding:0;
    --inv-gutter-x:1.2em;
    --inv-text-l-font-size: 1.8em;
    --inv-text-s-font-size: 1.8em;
  }
  .chartInfoDivider{
    height: 4.1em;
  }
  .chart{
    margin: 0 0 4.8em;
  }
  .divider{
    display: flex;
    --inv-gutter-x:0;
  }
  .buttonsContainer{
    justify-content: flex-end;
    margin-top: 1.2em;
  }
  .vsCol{
    margin:0 3.5em
  }
  .vsContainer{
    display: flex;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    background: var(--primary-color);
    border-radius: 50%;
    width: 3.6em;
    height: 3.6em;
    padding: 0.8em;
  }
  .chartInfoDivider{
    height: 2.5em;
  }
  .container {
    padding: 2.4em 1.2em 1.2em;
    font-size: 13.3px;
    max-width: 910px;
    margin: 2em auto 10em;
    border-radius: 1.6em;
    --inv-text-xl-font-size: 2.4em;
    --inv-text-l-font-size: 2.4em;
    --inv-text-xs-font-size: 1.2em;
    --inv-text-s-font-size: 1.2em;
    --inv-text-m-font-size: 2em;
    --inv-text-xxs-font-size: 0.8em;
    --inv-text-xxxxxl-font-size: 1.2em;
    --inv-text-xxl-font-size: 2.4em;
  }
}
