.textStructureContainer{
    --inv-text-l-font-size:0.6em;
    line-height:0
}

.container{
    --inv-text-s-font-size: 0.9em;
    --inv-gutter-y:initial;
}

.secondRowContainer{
    margin-top: 0.8em;
    justify-content: space-between;
    --inv-gutter-x: 0.4em;
}

.adviceContainer{
    justify-content: center;
}

@media (min-width: 576px) {
    .textStructureContainer{
        --inv-text-l-font-size:1.2em;
        --inv-text-xxs-font-size:0.7em;
        line-height:0
    }
    .container{
        --inv-text-s-font-size: 1.6em;
        --inv-text-xs-font-size: 0.6em;
        --inv-text-xxs-font-size: 0.7em;
    }
    .secondRowContainer{
        margin-top: 1.6em;
        --inv-gutter-x: 1.2em;
        align-items: center;
    }

    .adviceContainer{
        margin-top: 0.8em;
    }
}