.title{
    margin-bottom: 1.55em;
    display:flex;
    align-items: center;
    --inv-gutter-x: 0.4em;
}

.firstSubtitle{
    margin-bottom: 0.4em;
}
.lastRow{
    --inv-gutter-x:0;
    margin: 1.4em 0 0;
}

@media (min-width: 576px) {
    .bigQuantity{
        --inv-text-s-font-size: 1em;
    }
    .smallQuantity{
        --inv-text-xs-font-size: 0.7em;
    }
    .lastRow{
        margin: 1.4em 0 0;
    }
    .firstSubtitle{
        margin-bottom: 0;
    }
    .title{
        margin-bottom: 1.8em;
    }
    .container {
        --inv-text-s-font-size: 1.2em;
        --inv-text-xs-font-size: 0.8em;
    }
}
