.container{
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    background: var(--white, #fff);
    overflow-x: hidden;
    background: var(--white, #fff);
    border-radius: 0;
    padding: 1.6em 0.8em;
    margin-top: 1.2em;
    border-right: 0;
}

@media (min-width: 576px){
    .container{
        border-radius: 1.6em;
        margin-bottom: 22em;
        padding: 2.4em 1.6em 1.2em;
        margin-top: 4em;
        max-width: 64.5em;
    }
}