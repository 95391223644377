.buttonElement1{
    display: flex;
    justify-content: center;
    margin: auto;
    order: 3;
}
.divider{
    display: none;
}
.buttonElement2{
    display: flex;
    justify-content: center;
    margin: 0 auto 1.6em;
    order: 1;
}
.buttonElement3{
    display: flex;
    justify-content: center;
    margin: 0 auto 0.1em;
    order: 2;
}

@media (min-width: 576px) {
    .buttonElement1{
        order: inherit;
        margin: inherit;
        justify-content: flex-start;
    }
    .divider{
        display: flex;
        width: calc(100% + 4.4em);
        justify-content: flex-end;
    }
    .buttonElement2{
        order: inherit;
        margin: auto;
    }
    .buttonElement3{
        order: inherit;
        margin: inherit;
        justify-content: flex-end;
    }
}