$black-105: #00122b;
$black-100: #112645;
$black-95: #6d809c;
$black-80: #aabaca;
$black-60: #dbe1e7;
$black-40: #f0f5f9;
$black-20: #f9fcff;

$turquoise-105: #29adb7;
$turquoise-100: #33c5d1;
$turquoise-95: #50ced8;
$turquoise-80: #6dd6de;
$turquoise-60: #99e2e8;
$turquoise-40: #b6f0f5;
$turquoise-20: #e1f7f8;
$turquoise-05: #f3feff;

$blue-105: #083577;
$blue-100: #0e4dab;
$blue-95: #1b5fc5;
$blue-80: #548ee3;
$blue-60: #86ace5;
$blue-40: #bad3f7;
$blue-20: #e9f1fd;
$blue-05: #f5f9fe;

$gradient-blue: linear-gradient(
  93.64deg,
  #00d5e0 1%,
  #15a6da 26.85%,
  #004bba 86.58%,
  #0f4595 99.6%
);
$gradient-1: linear-gradient(
  180deg,
  #c7fcec 24.15%,
  #6cd6da 79.92%,
  #f1f1f1 84.44%
);
$gradient-2: linear-gradient(
  160.84deg,
  #c7fcec 38.36%,
  #6cd6da 67.16%,
  #f1f1f1 87.04%
);
$gradient-semaforo: linear-gradient(
  90deg,
  rgba(14, 231, 166, 0.6) 12.5%,
  rgba(255, 171, 71, 0.6) 56.36%,
  rgba(226, 46, 89, 0.6) 100%
);

$goal-1-1: #da1ba4;
$goal-1-2: #ec6cc8;
$goal-1-3: #ffb8ef;

$goal-2-1: #f5931f;
$goal-2-2: #ffab47;
$goal-2-3: #fff2e3;

$goal-3-1: #20d35d;
$goal-3-2: #95de5c;
$goal-3-3: #dbffb8;

$goal-4-1: #d3cc20;
$goal-4-2: #ebe561;
$goal-4-3: #fffcb8;

$goal-5-1: #0e4dab;
$goal-5-2: #548de3;
$goal-5-3: #e9f1fd;

$error: #e22e59;
$success: #4adbaf;
$disabled: #97aac6;

html {
  --primary-color: #{$turquoise-100};
  --primary-font-color: #ffffff;
  --primary-hover-color: #{$blue-100};
  --primary-gradient-color: #{$gradient-blue};
  --error-color: #{$error};
  --grey-dark-color: #{$black-95};
  --grey-medium-color: #{$black-80};
  --grey-light-color: #{$black-60};
  --inv-gutter-x: 1.5em;
  --black-20: #{$black-20};
  --gradient-1:#{$gradient-1};
  --white:#fff
}
:export {
}
