.contributionLink{
    cursor: pointer;
    font-size: 0.8em;
    font-weight: 400;
    margin: 0;
}

.contributionLinkActive{
    cursor: pointer;
    font-size: 0.8em;
    font-weight: 700;
    text-decoration: underline;
    margin: 0;
}

.contributionLink:hover{
    text-decoration: underline;
}

.paginationArrowContainerActive{
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.6em;
    height: 1.6em;
    background: #F0F5F9;
    border-radius: 50%;
}

.paginationArrowContainer{
    cursor: not-allowed;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    border-radius: 50%;
}

.paginationNumberContainer{
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.6em;
    height: 1.6em;
    background: transparent;
    border-radius: 50%;
    --inv-text-s-color: #083577;
    --inv-text-s-font-size: 0.8em;
}

.paginationNumberContainer:hover{
    background: #F0F5F9;
}

.paginationNumberContainerActive{
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.6em;
    height: 1.6em;
    background: #083577;
    border-radius: 50%;
    --inv-text-s-color: #FFFFFF;
    --inv-text-s-font-size: 0.8em;
}

.paginationContainer{
    justify-content: center;
    align-items: center;
    margin-top: 2.2em;
}

.paginationContainer > *{
    margin-right: 1em;
}