@media (min-width: 576px) {
    .headerContainer {
        background: #fff;
        padding:2.35em 6.825em;
        max-width:73.15em;
        margin:2.775em auto 0;
        border-radius:0.8em;
        border:0.05em solid #F0F5F9;
        box-shadow: 0px 8px 8px 1px rgba(170, 186, 202, 0.2);
        position: relative;
        z-index: 2;
    }
    .textStructureContainer{
        --inv-text-l-font-size:1.8em;
        --inv-text-xxs-font-size:0.8em;
        --inv-text-xs-font-size:1em;
        line-height:0
    }
    .contentContainer{
        max-width: 72em;
        padding: 6.95em 0 13.5em;
        position: relative;
        z-index: 1;
        background: #fff;
        margin: 0 auto;
    }
    .whiteContainer{
        width: 100vw;
        height: calc(100% + 3em);
        background: #fff;
        position: absolute;
        z-index: -1;
        margin-top: -9em;
    }
    .titleWithSubtitleContainer{
        margin-bottom: 3.2em;
        --inv-text-m-font-size: 2.4em;
        --inv-text-xs-font-size: 1.6em;
    }
    .titleWithSubtitleContainer > *:first-child{
        margin-bottom: 0.4em;
    }
    .goalCostChart{
        margin-bottom: 2.8em;
    }
    .goalCostChartValue{
        --inv-text-xxs-font-size: 0.8em;
        --inv-text-xs-font-size: 1.8em;
        --inv-gutter-y:2.9em
    }
    .goalCostChartValueUnit{
        --inv-text-xxs-font-size: 1em;
        --inv-text-xs-font-size: 1em;
    }
}