@media (min-width: 576px) {
    .closeIconContainer{
        display: none;
        margin: 2.2em 3.75em 0 0;
        cursor: pointer;
    }
    .container{
        max-width: 53.49em;
        padding: 5.2em;
    }

    .container > *{
        margin-top: 2em;
    }

    .container > *:first-child{
        margin-top: 0;
    }

    .container> *:nth-child(2){
        margin-top: 1em;
    }
}