html {
  --primary-color: #33c5d1;
  --primary-font-color: #ffffff;
  --primary-hover-color: #0e4dab;
  --primary-gradient-color: linear-gradient(93.64deg, #00d5e0 1%, #15a6da 26.85%, #004bba 86.58%, #0f4595 99.6%);
  --error-color: #e22e59;
  --grey-dark-color: #6d809c;
  --grey-medium-color: #aabaca;
  --grey-light-color: #dbe1e7;
  --inv-gutter-x: 1.5em;
  --black-20: #f9fcff;
  --gradient-1:linear-gradient(180deg, #c7fcec 24.15%, #6cd6da 79.92%, #f1f1f1 84.44%);
  --white:#fff ;
}

