.container{
    display: none;
}

.titleContainer{
    display: none;
}

.nextApportationContainer{
--inv-text-xxs-font-size:0.8em;
--inv-text-l-font-size:1.8em;
--inv-text-xs-font-size:1em;
}

.accumulatedContainer{
    --inv-text-xxs-font-size:0.6em;
    --inv-text-l-font-size:0.8em;
    --inv-text-xs-font-size:0.7em;
}

.contentHeaderContainer{
    padding: 3.2em 1.8em;
}

.smallHeaderTextStructures{
    --inv-text-xxs-font-size:0.6em;
    --inv-text-l-font-size:0.8em;
    --inv-text-xs-font-size:0.7em;
}

.infoHeaderContainer{
    justify-content:space-between;
    align-items:center;
}

.apportationsContainer{
    --inv-gutter-x: 0.8em;
    --inv-text-l-font-size: 0.8em;
    justify-content: space-between;
}

.apportationsContainer + *{
    margin-top: 0.8em;
}

@media (min-width: 576px) {
    .titleWithSubtitleContainer{
        margin-bottom: 3.2em;
    }
    .titleWithSubtitleContainer > *:first-child{
        margin-bottom: 0.4em;
    }
    .contentCol10 > *{
        margin-bottom: 6em;
    }
    .contentCol10 > *:last-child{
        margin-bottom: 0;
    }
    .contentContainer{
        max-width: 72em;
        padding: 3.2em 3.9em;
        position: relative;
        z-index: 1;
        background: #fff;
        margin: 0 auto;
    }
    .contentContainer > *:nth-child(3){
        margin-bottom:6em
    }
    .container{
        display: initial;
        top: 42em;
        position: absolute;
        background: #fff;
        width: 100%;
        left: 0;
    }
    .apportationsContainer{
        --inv-text-l-font-size: inherit;
        --inv-gutter-x: 3.2em;
    }
    .whiteContainer{
        width: 100vw;
        height: calc(100% - 10em);
        background: #fff;
        position: absolute;
        z-index: 0;
        margin-top: 10em;
    }
    .apportationsContainer + *{
        margin-top: 1.5em;
    }
    .titleContainer{
        justify-content: center;
    }
    .nextApportationContainer{
        --inv-text-xxs-font-size:0.8em;
        --inv-text-l-font-size:3.95em;
        --inv-text-xs-font-size:1.95em;
    }
    .accumulatedContainer{
        --inv-text-xxs-font-size:0.8em;
        --inv-text-l-font-size:3.95em;
        --inv-text-xs-font-size:1.95em;
    }
    .smallHeaderTextStructures{
    --inv-text-xxs-font-size: 0.8em;
    --inv-text-l-font-size: 1.8em;
    --inv-text-xs-font-size: 1em;
    }
    .infoHeaderContainer{
        justify-content:center;
    }
}