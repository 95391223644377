.buttonElement1{
    display: flex;
    justify-content: center;
    order: 2;
    margin-top: 0.8em;
}
.buttonElement2{
    display: flex;
    justify-content: center;
    order: 1;
}

.divider{
    display: none;
}

.marginQ1Element1{
    margin:1.6em 0;
}

.marginQ1Element2{
    margin:0 0 1.6em;
}

.marginQ1Element3{
    margin:0 0 1.6em;
}

.marginQ2Element1{
    margin:1.6em 0;
}

.marginQ2Element2{
    margin:0 0 1.6em;
}

.marginQ2Element3{
    margin:0 0 1.6em;
}

.marginQ3Element1{
    margin: 1.6em 0;
}

.marginQ4Element1{
    margin: 1.6em 0;
}

.marginQ4Element2{
    margin: 4em 0 1.6em;
}

.marginQ4Element3{
    margin: 0 0 1.6em;
}

.marginQ5Element1{
    margin: 1.6em 0;
}

.marginQ5Element2{
    margin: 0 0 1.6em;
}

@media (min-width: 576px) {
    .marginQ2Element1{
        margin:2em 0 0.8em;
    }

    .marginQ2Element2{
        margin:0 0 0.8em;
    }

    .marginQ2Element3{
        margin:0 0 2em;
    }

    .marginQ1Element1{
        margin:2em 0 0.8em;
    }

    .marginQ1Element2{
        margin:0 0 0.8em;
    }

    .marginQ1Element3{
        margin:0 0 2em;
    }

    .marginQ3Element1{
        margin: 4em 0;
    }

    .marginQ4Element1{
        margin: 2em 0;
    }

    .marginQ4Element2{
        margin: 6em 0 2em;
    }

    .marginQ4Element3{
        margin: 0 0 2em;
    }

    .marginQ5Element1{
        margin: 2em 0;
    }

    .marginQ5Element2{
        margin: 0 0 2em;
    }

    .divider{
        display: flex;
        width: calc(100% + 4.4em);
        justify-content: flex-end;
    }

    .buttonElement1{
        display: flex;
        justify-content: flex-start;
        order: 1;
        margin-top: initial;
    }
    .buttonElement2{
        display: flex;
        justify-content: flex-end;
        order: 2;
    }

    .container{
        --inv-text-xl-font-size: 2.4em;
        --inv-text-l-font-size: 2.4em;
        --inv-text-xs-font-size: 1.2em;
        --inv-text-s-font-size: 1em;
        --inv-text-xxs-font-size: 1em;
        --inv-text-xxxxxl-font-size: 1.2em;
        --inv-text-xxl-font-size: 2.4em;
    }
}