.container {
    font-size: 20px;
    max-width: 1290px;
    margin: 1.2em 0 0;
    background: #fff;
    border-radius: 0;
    width: 100%;
    padding: 2.4em 0.8em 1.6em;
}

@media (min-width: 576px) {
    .container {
        padding: 5.2em 3.7em 1.2em;
        font-size: 13.3px;
        max-width: 910px;
        margin: 2em auto 10em;
        border-radius: 1.6em;
    }
}