@font-face {
  font-family: "Inter";
  src: url("fonts/inter/Inter-Light.woff2") format("woff2");
  font-weight: 300;
}
@font-face {
  font-family: "Inter";
  src: url("fonts/inter/Inter-Regular.woff2") format("woff2");
  font-weight: normal;
}
@font-face {
  font-family: "Inter";
  src: url("fonts/inter/Inter-Medium.woff2") format("woff2");
  font-weight: 500;
}
@font-face {
  font-family: "Inter";
  src: url("fonts/inter/Inter-SemiBold.woff2") format("woff2");
  font-weight: 600;
}
@font-face {
  font-family: "Inter";
  src: url("fonts/inter/Inter-Bold.woff2") format("woff2");
  font-weight: bold;
}

html,
body {
  --inv-button-font-size: 0.8em;
  font-size: 20px !important;
  padding: 0;
  margin: 0;
  font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.PrivatePickersYear-yearButton {
  font-size: 16px !important;
  font-weight: 600 !important;
  border-radius: 32px !important;
  border: 2px solid #aabaca !important;
  flex: 1 0 31.33% !important;
  margin: 3px !important;
  height: 49px !important;
}
.PrivatePickersYear-yearButton:hover {
  background-color: transparent !important;
  border: 2px solid #0e4dab !important;
}
.PrivatePickersYear-yearButton.Mui-selected {
  background-color: transparent !important;
  color: #0e4dab !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  border-radius: 32px !important;
  border: 4px solid #0e4dab !important;
}
.PrivatePickersYear-yearButton.Mui-selected:hover {
  border: 2px solid #0e4dab !important;
}

.PrivatePickersYear-root {
  flex-basis: 33% !important;
}

.MuiTypography-root.MuiTypography-subtitle1.PrivatePickersMonth-root {
  pointer-events: auto;
  font-size: 16px;
  height: 49px;
  font-weight: 600;
  background-color: transparent;
  border-radius: 32px;
  border: 2px solid #aabaca;
  margin: 3px;
  flex: 1 0 31.33%;
}
.PrivatePickersMonth-root:hover {
  border: 2px solid #0e4dab !important;
}
.MuiTypography-root.MuiTypography-h5.PrivatePickersMonth-root.Mui-selected {
  color: #0e4dab;
  pointer-events: auto;
  font-size: 16px;
  font-weight: 600;
  background-color: transparent;
  border-radius: 32px;
  border: 4px solid #0e4dab;
  margin: 3px;
  height: 49px;
  flex: 1 0 31.33%;
}

@media (min-width: 576px) {
  body {
    font-size: 13px !important;
    --inv-button-font-size: 1em;
  }
}
