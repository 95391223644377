.container {
    font-size: 20px;
    max-width: 1290px;
    margin: 1.2em 0 0;
    background: #fff;
    border-radius: 0;
    width: 100%;
    padding: 2.4em 0.8em 1.6em;
}

.img-container {
    width: 100%;
}

.secondTitle{
    margin:1.8em 0 0;
    justify-content: center;
    display: flex;
}

.chartsContainer{
    margin:1.6em 0;
    align-items: flex-end;
    justify-content: center;
    --inv-gutter-x:0.5em;
}

.chartElementTitle{
    justify-content: center;
    margin: 0 0 0
}
.lastTitle{
    margin: 1.6em 0;
    justify-content: center;
}
.divider{
    display: none;
}


.buttonsContainer{
    display: flex;
    justify-content: space-between;
    padding: 0;
    --inv-gutter-x: 0;
}

.buttonElement1{
    display: flex;
    justify-content: center;
    order: 2;
    margin-top: 0.8em;
}
.buttonElement2{
    display: flex;
    justify-content: center;
    order: 1;
}

@media (min-width: 576px) {

    .buttonsContainer{
        display: flex;
        justify-content: space-between;
        padding: 1.2em 0 0;
        --inv-gutter-x: 0;
    }
    .buttonElement1{
        display: flex;
        justify-content: flex-start;
        order: 1;
        margin-top: initial;
    }
    .buttonElement2{
        display: flex;
        justify-content: flex-end;
        order: 2;
    }
    .secondTitle{
        margin:4em 0 0;
    }
    .chartsContainer{
        margin:2.5em 0 2.05em;
        --inv-gutter-x:1.2em;
    }
    .chartElementTitle{
        --inv-text-xxs-font-size: 0.8em;
        margin: 0 0 0
    }
    .lastTitle{
        margin: 2.05em 0 7.9em;
    }
    .divider{
        display: flex;
    }
    .container {
        padding: 2.4em 1.25em 1.2em;
        font-size: 13.3px;
        max-width: 910px;
        margin: 2em auto 10em;
        border-radius: 1.6em;
        --inv-text-xl-font-size: 2.4em;
        --inv-text-l-font-size: 2.4em;
        --inv-text-xs-font-size: 1.6em;
        --inv-text-s-font-size: 1.6em;
        --inv-text-xxs-font-size: 1em;
        --inv-text-xxxxxl-font-size: 1.2em;
        --inv-text-xxl-font-size: 2.4em;
    }
    .img-container {
        width: 41%;
    }
}
