.container{
    padding: 1.6em 0.8em;
    --inv-gutter-x: 0;
}
.chartContainer{
    margin: 2em 0 1.2em;
}

.titleContainer{
    margin-bottom:0.8em;
}

.sliderContainer{
    margin: 4.25em 0 1.6em;
}

@media (min-width: 576px) {
    .container{
        --inv-text-l-font-size: 2.4em;
        --inv-text-s-font-size: 1em;
        padding: 4.4em 1.2em 1.2em;
    }

    .descriptionAndChartContainer{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .chartContainer{
        margin: 0 0 0.6em;
    }
    .titleContainer{
        margin-bottom:1.25em;
    }
    .sliderContainer{
        margin: 7.75em 0 5.15em;
    }

    .closeIconContainer{
        cursor: pointer;
        margin: 1em 1em 0 0;
    }
    .buttonsContainer{
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
    }
    .testAndSaveButtonsContainer{
        --inv-gutter-x:0.8em;
        display: flex;
        flex-direction: row-reverse;
    }
    .continueButtonContainer{
        margin-bottom: 2.8em;
    }
}